<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'">
    <div style="text-align: right;z-index: 2;" >
      <el-button size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" style="text-align: right;"  type="primary" @click="printopen()">{{ $t('All.打印') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 0 || form.State == 7) && $store.state.Login.Jurisdiction < 6" type="warning" @click="qianhe = true">{{this.$t('All.签核')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 6 && $store.state.Login.Username == form.Inspector) || $store.state.Login.Jurisdiction == 0" type="success" @click="dialogVisibleprin = true">{{this.$t('All.打印贴纸')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="((form.State == 1 || form.State == 3) && $store.state.Login.Username == form.Inspector)" type="primary" @click="NewIQCuserzaigo(form,0)">{{this.$t('system.songceng')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="((form.State == 1 || form.State == 3) && ($store.state.Login.Username == form.Inspector || $store.state.Login.Jurisdiction == 0) && !isdisabled)" type="primary" @click="UserDataUp(form,3)">{{ $t('All.更新') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="($store.state.Login.Username == form.Inspector || $store.state.Login.Jurisdiction == 0)  && (form.State == 1 || form.State == 3) ||  $store.state.Login.Jurisdiction == 0" type="danger" @click="Delete(form)">{{this.$t('system.zuofei')}}</el-button>
      <el-button size="small" type="danger" v-if="(form.State == 3 || form.State == 1) && $store.state.Login.Username == form.Inspector"  @click="NewIQCuserzaigo(form,7,1)">{{ $t('All.紧急送呈') }}</el-button>
      <el-button size="small" type="primary" v-if="!isdisabled"   @click="IsUpload(3,FileList3)">{{this.$t('system.fjian')}}</el-button>
      <el-button size="small" type="primary" v-if="isdisabled"   @click="IsUpload(3,FileList3)">{{this.$t('system.chakanfj')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Inspector && (form.State == 1 || form.State == 3) && isdisabled" type="success" @click="isdisabled = false">{{this.$t('system.unlock')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Inspector && (form.State == 1 || form.State == 3) && !isdisabled" type="primary" @click="isdisabled = true" >{{this.$t('system.lock')}}</el-button>
    </div>
     <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
     <h3 style="text-align: center;">{{this.$t('system.jinliaojy')}}</h3>
     <h4 style="text-align: center;">{{this.$t('system.系统单号')}}：{{form.Numbers}}</h4>
     <div style="text-align: center;">
      <span v-if="form.State == 6 || form.State == 2|| form.State == 5" style="text-align: center;font-size: 28px;color: red;">{{$PublicJs.Result(form.Result)}}</span><br>
      <span style="text-align: center;font-size: 28px;color: red;">{{$PublicJs.State(form.State)}}</span>
    </div>
    <h6 style="text-align: left; color: red;" v-if="Msgtieleng">{{$t('system.警告')}}：{{ Msgtieleng }}</h6>
     <p v-if="!isdisabled" style="text-align: right;padding-right: 10px;">{{ $t('All.进料日期') }}：<el-date-picker
            v-model="form.Inspection_No"
            type="date"
            :placeholder="this.$t('All.选择日期')"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd">
          </el-date-picker>
      </p>
     <h4 v-if="isdisabled" style="text-align: right;padding-right: 10px; ">{{ $t('All.进料日期') }}：{{formatDate(form.Inspection_No)}}</h4>
     <div style=" padding: 10px; text-align: center; line-height: 2vw;font-size: 14px;">
       <table border="1"  align="center" cellpadding="10" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width: 100%;"  cellspacing="0">
           <tr>
              <td colspan="2">{{this.$t('IQC.检验基准表')}}</td>
              <td>{{this.$t('IQC.工程图面')}}</td>
              <td>{{this.$t('IQC.承认书')}}</td>
              <td  colspan="2">{{this.$t('IQC.品名')}}</td>
              <td  colspan="2">{{this.$t('IQC.料号')}}</td>
              <td  colspan="2">{{this.$t('IQC.订单号')}}</td>

           </tr>
           <tr>
            <td  colspan="2" class="tdd">
              <el-tooltip effect="dark" :content="form.Inspection_Table" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Inspection_Tableurl)">{{ form.Inspection_Table }}</span>
                  </div>
                </el-tooltip>
            </td>
            <td class="tdd">
              <el-tooltip effect="dark" :content="form.Drawing_Surface" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Drawing_Surfaceurl)">{{ form.Drawing_Surface }}</span>
                  </div>
                </el-tooltip>
            </td>
            <td class="tdd">
              <el-tooltip effect="dark" :content="form.Acknowledgment" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Acknowledgmenturl)">{{ form.Acknowledgment }}</span>
                  </div>
                </el-tooltip>
            </td>
            <td colspan="2" class="tdd"><el-input :disabled="isdisabled" v-model="form.Product_name"></el-input></td>
            <td colspan="2" class="tdd"><el-input disabled v-model="form.Item_No"></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled="isdisabled" @blur="Geterpitem" v-model="form.Order_Number"></el-input></td>
           </tr>
           <tr>
             <td colspan="2">{{ $t('All.标准样品编号') }}</td>
             <td >{{ $t('All.物料单位') }}</td>
             <td >{{this.$t('IQC.交货数')}}</td>
             <td >{{this.$t('IQC.入库数')}}</td>
             <td >{{this.$t('IQC.抽样数')}}</td>
             <td colspan="2">{{this.$t('IQC.抽样类型')}}</td>
             <td colspan="2">{{this.$t('IQC.供应商')}}</td>
           </tr>
           <tr>
             <td  colspan="2" class="tdd"><el-input :disabled="isdisabled" v-model="form.Standard_sample"></el-input></td>
             <td class="tdd"> <!--<el-input v-model="form.Company"></el-input>-->
              <el-select v-model="form.Company" :disabled="isdisabled" filterable allow-create :placeholder="$t('All.单位')" @change="chuangjcompany" :style="{width: '100%'}">
                <el-option label="G" value="G"></el-option>
                <el-option label="KG" value="KG"></el-option>
                <el-option label="PC" value="PC"></el-option>
                <el-option label="M" value="M"></el-option>
                <el-option label="KM" value="KM"></el-option>
              </el-select>
            </td>
             <td class="tdd"><el-input :disabled="isdisabled" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="form.Delivery"></el-input></td>
             <td class="tdd"><el-input :disabled="isdisabled" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="form.Warehousing"></el-input></td>
             <td class="tdd"><el-input :disabled="isdisabled" v-model="form.Sampling"></el-input></td>
             <td  colspan="2" class="tdd"><el-input disabled v-model="form.Sampling_type"></el-input></td>
             <td  colspan="2" class="tdd"><el-input :disabled="isdisabled" v-model="form.Supplier"></el-input></td>
           </tr>
           <tr>
             <td colspan="10"><el-input disabled v-model="form.Standard"><template slot="prepend">{{this.$t('IQC.执行标准')}}</template></el-input></td>
           </tr>
           <tr>
             <td >{{this.$t('IQC.检验项目')}}</td>
             <td colspan="2">{{this.$t('IQC.检验标准')}}</td>
             <td colspan="1">{{ $t('All.检验工具') }}</td>
             <td colspan="2">{{ $t('All.判定基准') }}</td>
             <td colspan="1">{{this.$t('All.实验频率') }}</td>
             <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
             <td colspan="2">{{this.$t('IQC.备注')}}/{{ $t('All.测试报告') }}</td>
           </tr>
           <tr v-for="(item, index) in form.Testlist" :key="'Testlist' + index">
             <td>{{ item.Title }}</td>
             <td colspan="2" style="text-align: left;">{{ item.Standard }}</td>
             <td colspan="1" style="text-align: left;">{{ item.Tool }}</td>
             <td colspan="2" style="text-align: left;">{{ item.Benchmark }}</td>
             <td colspan="1">{{$PublicJs.Frequencyfun(item.Frequency) }}</td>
             <td class="tdd" v-if="isdisabled">
                  <span :style="item.Determine == 'Fail'?'color: red;':''">{{ item.Determine }}</span>
              </td>
             <td colspan="1" v-if="!isdisabled">
                    <el-select v-if="item.Determinetype != 1" :disabled="isdisabled" v-model="item.Determine" :placeholder="item.Determinetype == 1?$t('All.实验室判定'):$t('IQC.请判定')">
                        <el-option label="Pass" value="Pass"></el-option>
                        <el-option label="Fail" value="Fail"></el-option>
                        <el-option label="NA" value="NA"></el-option>
                    </el-select>
                    <span v-if="item.Determinetype == 1">{{item.Determine == ''?$t('All.实验室判定'):item.Determine}}</span>
               </td>
               <td colspan="2">
                <el-input type="textarea" v-if="(item.Determinetype == 0)" :rows="3" v-model="item.Remarks"></el-input>
                <el-link @click="lookshiyan(item,index)" type="primary" v-if="item.Determinetype == 1 && item.Remarks.length > 0 && item.Remarks != ''" >{{item.Remarks}}</el-link>
                <el-button size="mini" v-if="item.Determinetype == 1 && !isdisabled && !item.Remarks" type="primary"   @click="Testbox(item, index)" >{{ $t('All.送测单') }}</el-button>
                <el-button style="margin-left: 0 !important;margin-top: 5px;" size="mini" v-if="!isdisabled && item.Determinetype == 1 && (item.Remarks.length === 0 || item.Remarks == '')" type="warning"  @click="mzTestbox(item, index)" >{{ $t('All.免做单') }}</el-button>
              </td>
            </tr>
           <tr>
               <td class="tdd" rowspan="2">{{this.$t('IQC.规格要求')}}</td>
               <td class="tdd" colspan="2">{{this.$t('IQC.量测仪器编号')}}</td>
               <td colspan="7" :disabled="isdisabled" class="tdd"><el-input :disabled="isdisabled" v-model="form.Measuring"></el-input></td>
           </tr>
           <tr>
              <td colspan="1" class="tdd" >{{ $t('All.单位') }}/{{ $t('All.标注') }}</td>
              <td colspan="5" class="tdd" >{{this.$t('IQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
           </tr>
           <tr v-for="(text, index) in form.NewIQCParcss" :key="index">
               <td class="tdd">{{ text.Specification }}</td>
               <td class="tdd">{{ text.Units_Dimensions }}</td>
               <td class="tdd"><el-input :disabled="isdisabled" v-model="text.Text1" v-enter-next-input></el-input></td>
               <td class="tdd"><el-input :disabled="isdisabled" v-model="text.Text2" v-enter-next-input></el-input></td>
               <td class="tdd"><el-input :disabled="isdisabled" v-model="text.Text3" v-enter-next-input></el-input></td>
               <td class="tdd"><el-input :disabled="isdisabled" v-model="text.Text4" v-enter-next-input></el-input></td>
               <td class="tdd"><el-input :disabled="isdisabled" v-model="text.Text5" name="Text5" v-enter-next-input @change="Keyenter(text,index)"></el-input></td>
               <td class="tdd"><el-input :disabled="isdisabled" v-model="text.Max"></el-input></td>
               <td class="tdd"><el-input :disabled="isdisabled" v-model="text.Min"></el-input></td>
               <td class="tdd" v-if="!isdisabled">
                  <el-select :disabled="isdisabled" v-model="text.Determine" :placeholder="$t('All.请选择')" @change="addlist(text,index,$event)">
                      <el-option label="Pass" value="Pass"></el-option>
                      <el-option label="Fail" value="Fail"></el-option>
                      <el-option :label="$t('All.判定')" :value="$t('All.判定')"></el-option>
                      <el-option :label="$t('All.增加')" :value="$t('All.增加')"></el-option>
                      <el-option :disabled="form.NewIQCParcss.length == 1" :label="$t('All.删除')" :value="$t('All.删除')"></el-option>
                  </el-select>
              </td>
              <td class="tdd" v-if="isdisabled">
                  <span :style="text.Determine == 'Fail'?'color: red;':''">{{ text.Determine }}</span>
              </td>
           </tr>
           <tr>
            <td style="text-align: left;" colspan="10" v-html="this.$t('IQC.beizhu')">
            </td>
            <!-- <td colspan="10"> -->
              <!-- <el-input type="textarea" disabled :rows="6" v-html="this.$t('IQC.beizhu')">
              </el-input> -->
          </tr>
       </table>
       <el-row>
         <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.核准')}}: {{form.Approval}}</span></el-col>
         <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.检验员')}}: {{form.Inspector}}</span></el-col>
       </el-row>
       <el-row>
         <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
         <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYP1001-37-K</span></el-col>
       </el-row>
     </div>
     <el-form style="max-width: 800px;margin: auto;" size="medium" label-width="90px"
      label-position="top">
    <el-form-item :label="$t('All.签核记录')">
        <el-radio-group  style="padding-bottom: 20px;padding-left: 5%;" v-model="reverse">
          <el-radio :label="true">{{ $t('All.倒序') }}</el-radio>
          <el-radio :label="false">{{ $t('All.正序') }}</el-radio>
        </el-radio-group>
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(activity, index) in Signlog"
            :key="index"
            :timestamp="activity.Date">
            {{ $PublicJs.State(activity.State)}}
            <p>{{activity.Username}}</p>
            <p>{{activity.Notes}}</p>
          </el-timeline-item>
        </el-timeline>
      </el-form-item>
    </el-form>
    <iqcUpload  v-if="isboll" :Number = "form.Numbers" :isdata="isdisabled" :Type = "Type" :FileList = "FileList" :FalseUpload="falseUpload" :Uphandsuccess="uphandsuccess"></iqcUpload>
  <NewiqcTestsheet v-if="Texttable" :formxin="form" :Test_Numbers="Test_Numbers" :Texttablebool="Texttablebool" :Test_Item="Test_Item" :Test_Index="Test_Index" :Test_Supplier="Test_Supplier" :Test_Product_name="Test_Product_name" :Texttablefunadd="Texttablefunadd"></NewiqcTestsheet>
  <IQCqianhe v-if="qianhe" :Qianhecolse = "Qianhecolse" :NewIQCuserheke="NewIQCuserheke" :Formstate="form.State"/>
  <el-dialog
    :title="$t('All.打印选择')"
    :visible.sync="dialogVisibleprin"
    width="840px"
    :before-close="handleClose" append-to-body>
    <el-form ref="formrules" status-icon :model="prinform" label-position="right" label-width="100px">
      <!-------------------------------------->
      <!-------------------------------------->
      <el-form-item
        v-for="(domain, index) in prinform.Boxlist"
        :label="$t('All.打印箱')"
        :key="index"
      >
        <el-input v-model="domain.Box" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = null; }" style="width:90px;" autocomplete="off"></el-input>
        <span style="padding: 10px;">{{$t('All.数量')}}:</span><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" v-model="domain.Boxquantity" style="width:90px;" autocomplete="off"></el-input>
        <span style="padding: 10px;">{{$t('All.生产批号')}}:</span>
        <el-input v-model="domain.Batchnumber"
          style="width:120px;"
          autocomplete="off"
          onkeyup="value=value.replace(/[^a-zA-Z0-9*]/g,'').toUpperCase()"
          @blur="domain.Batchnumber=domain.Batchnumber.replace(/[^a-zA-Z0-9*]/g,'').toUpperCase()"
          oninput="if (value.trim() === '') { value = null; }">
        </el-input>
        <span style="padding: 10px;">{{$t('All.结果')}}:</span>
        <el-select style="width: 107px;" v-model="domain.Pass" :placeholder="$t('All.请选择')">
          <el-option
            v-for="item in passoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button v-show="index + 1 === prinform.Boxlist.length" style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"  @click="addDomainbut()"></el-button>
        <el-button v-show="prinform.Boxlist.length > 1" size="mini" type="danger" icon="el-icon-delete"  @click="removeDomainbut(domain)"></el-button>
      </el-form-item>
      <!-------------------------------------->
      <!-------------------------------------->
      <el-form-item :label="$t('All.月份')">
        <el-select v-model="prinform.Month" :placeholder="$t('All.请选择月份区域')">
          <el-option
            v-for="item in $PublicJs.Printer"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span :style="`float: right; color: ${item.color}; font-size: 13px;`">{{ item.value }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('All.检验日期')">
        <el-date-picker
          v-model="prinform.Date"
          type="date"
          :placeholder="$t('All.检验日期')" value-format="yyyy/MM/dd">
        </el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('All.打印机')">
        <el-select v-model="prinform.Printer" value-key="Id"  :placeholder="$t('All.请选择打印机')">
          <el-option
            v-for="item in this.$store.state.PrinterData"
            :key="item.Name"
            :label="item.Wz"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="warning" @click="Newiqcprint(1)">{{ $t('All.完结不打印贴纸') }}</el-button>
      <el-button type="primary" @click="Newiqcprint(0)">{{ $t('All.打印贴纸') }}</el-button>
      <el-button type="primary" v-if="form.State == 2" @click="Newiqcprint(2)">{{ $t('All.重新打印贴纸') }}</el-button>
      <el-button @click="dialogVisibleprin = false">{{$t('All.取消')}}</el-button>
    </div>
  </el-dialog>
  <el-dialog
  :title="$t('All.请输入已做实验单号')"
  :visible.sync="yzdialogVisible"
  width="500px"
  :before-close="handleClose" append-to-body>
  {{$t('All.实验单号')}}：<el-input style="width: 300px;border: 1px solid #DCDFE6 !important;border-radius: 5px;" v-model="Mz_Numbers"></el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="yzdialogVisible = false">{{$t('All.取消')}}</el-button>
    <el-button type="primary" @click="Gettestbaogao()">{{ $t('All.确认') }}</el-button>
  </span>
</el-dialog>
</div>
 </template>
<script>
import iqcUpload from './Upload/NewiqcUserUpload.vue'
import NewiqcTestsheet from './Upload/NewiqcTestsheet'
import IQCqianhe from './Upload/IQCqianhe'
import { mapMutations } from 'vuex'
import Vue from 'vue'
// 自定义指令，实现按下enter后，光标自动跳转下一个输入框
Vue.directive('enterNextInput', {
  inserted: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      if (charcode === 13) {
        try {
          var dom = document.getElementsByTagName('input')
          for (var i = 0; i < dom.length; i++) {
            if (dom[i] === document.activeElement) {
              if (dom[i].name === 'Text5') {
                dom[i + 4].focus()
              } else {
                dom[i + 1].focus()
              }
              return
            }
          }
        } catch (error) {

        }
      }
    })
  }
})
export default {
  data () {
    return {
      form: {},
      isdisabled: true,
      isqpisboot: false,
      FileList: [],
      FileList3: [],
      FileList4: [],
      Type: 0,
      Index: '',
      isboll: false,
      dialogVisibleprin: false,
      prinform: {
        Boxlist: [
          {
            Box: 0,
            Boxquantity: 0,
            Batchnumber: '',
            Pass: 'Pass'
          }
        ],
        Month: this.$PublicJs.Dateselection(),
        Printer: this.$store.state.PrinterData[0],
        Date: ''
      },
      Texttable: false,
      Test_Item: '',
      Test_Index: '',
      Test_Supplier: '',
      Test_Product_name: '',
      Test_Numbers: '',
      Mz_Numbers: '',
      Mz_Index: '',
      Msgtieleng: '',
      qianhe: false,
      Signlog: [],
      reverse: true,
      yzdialogVisible: false,
      Mz_day: '',
      passoptions: [{
        value: 'Pass',
        label: 'Pass'
      }, {
        value: 'Fail',
        label: 'Fail'
      }, {
        value: 'Full',
        label: 'Full(全检)'
      }]
    }
  },
  components: {
    iqcUpload,
    NewiqcTestsheet,
    IQCqianhe
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    if (this.$store.state.UserData === null) {
      this.$message.success(this.$t('All.数据不存在'))
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.新IQC检验单') + '-' + this.$store.state.UserData)
    } else {
      this.form = this.$store.state.UserData
      this.prinform.Date = new Date(this.form.Inspection_No)
      if (this.form.State !== 2) {
        this.GetSdaten(this.form.Item_No)
      }
      this.FQCSignloglist(this.form.Numbers)
    }
  },
  mounted () {
    this.IQCUserGetAllFiles(this.form.Numbers)
    if (!this.form.Inspection_Table) {
      this.Msgtieleng += this.$t('IQC.无检验基准')
    }
    if (!this.form.Drawing_Surface) {
      this.Msgtieleng += this.$t('IQC.无工程图面')
    }
    if (!this.form.Acknowledgment) {
      this.Msgtieleng += this.$t('IQC.无承认书')
    }
  },
  methods: {
    Qianhecolse () {
      this.qianhe = false
    },
    Keyenter (item, index) {
      this.form.NewIQCParcss[index].Determine = ''
      const valany = []
      if (item.Text1 !== null && item.Text1 !== '' && item.Text1 !== undefined) {
        valany.push(parseFloat(item.Text1))
      }
      if (item.Text2 !== null && item.Text2 !== '' && item.Text2 !== undefined) {
        valany.push(parseFloat(item.Text2))
      }
      if (item.Text3 !== null && item.Text3 !== '' && item.Text3 !== undefined) {
        valany.push(parseFloat(item.Text3))
      }
      if (item.Text4 !== null && item.Text4 !== '' && item.Text4 !== undefined) {
        valany.push(parseFloat(item.Text4))
      }
      if (item.Text5 !== null && item.Text5 !== '' && item.Text5 !== undefined) {
        valany.push(parseFloat(item.Text5))
      }
      this.form.NewIQCParcss[index].Max = Math.max.apply(Math, valany)
      this.form.NewIQCParcss[index].Min = Math.min.apply(Math, valany)
      var dataine = this.$PublicJs.Calculation(item.Specification, this.form.NewIQCParcss[index].Max, this.form.NewIQCParcss[index].Min)
      this.form.NewIQCParcss[index].Determine = dataine
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    addDomainbut () {
      this.prinform.Boxlist.push({
        Box: '',
        Boxquantity: '',
        Batchnumber: '',
        Pass: 'Pass'
      })
    },
    async GetSdaten (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/GetSdaten?Numbers=' + row)
        if (res.status !== 200) return
        if (res.response == null) {
          return this.$message.error(this.$t('All.系统暂无') + row + this.$t('All.的样品'))
        } else {
          this.form.Standard_sample = res.response
        }
      } catch (error) {
        alert(error)
      }
    },
    removeDomainbut (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          if (this.prinform.Boxlist.length === 1) {
            return this.$message.error(this.$t('All.最少保留一列'))
          }
          var index = this.prinform.Boxlist.indexOf(item)
          if (index !== -1) {
            this.prinform.Boxlist.splice(index, 1)
          }
        })
        .catch(() => {
        })
    },
    IsUpload (type, filelist) {
      this.Type = type
      this.FileList = filelist
      this.isboll = true
    },
    falseUpload () {
      this.isboll = false
    },
    uphandsuccess (type, listdata, state) {
      switch (type) {
        case 0:
          this.form.Inspection_Table = state === 1 ? listdata[0].name : ''
          this.FileList0 = listdata
          break
        case 1:
          this.form.Drawing_Surface = state === 1 ? listdata[0].name : ''
          this.FileList1 = listdata
          break
        case 2:
          this.form.Acknowledgment = state === 1 ? listdata[0].name : ''
          this.FileList2 = listdata
          break
        case 3:
          this.FileList3 = listdata
          break
        default:
          break
      }
    },
    mzTestbox (item, index) {
      this.Mz_day = item.Frequency
      this.Mz_Index = index
      this.yzdialogVisible = true
    },
    addDomain (item, index) {
      const contr = {
      //
        Number: item.Number,
        //
        Index: index + 1,
        //
        Specification: item.Specification,
        //
        Units_Dimensions: item.Units_Dimensions,
        //
        Text1: '',
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        //
        Max: '',
        //
        Min: '',
        //
        Determine: ''
      }
      this.form.NewIQCParcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.NewIQCParcss.length; i++) {
        this.form.NewIQCParcss[i].Index = i
      }
    },
    removeDomain (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.NewIQCParcss.indexOf(item)
          if (index !== -1) {
            this.form.NewIQCParcss.splice(index, 1)
          }
          for (let i = 0; i < this.form.NewIQCParcss.length; i++) {
            this.form.NewIQCParcss[i].Index = i
          }
        })
        .catch(() => {
        })
    },
    ...mapMutations(['UserData', 'ConductIs']),
    async IQCUserGetAllFiles (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.get('/api/Newiqc/IQCUserGetAllFiles?Number=' + row + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        if (res.response.length > 0) {
          res.response.forEach(element => {
            element.url = this.$Upload + '/' + element.url
          })
        }
        if (res.response.length > 0) {
          for (let index = 0; index < res.response.length; index++) {
            if (res.response[index].Type === 3) {
              this.FileList3.splice(0, 0, res.response[index])
            }
          }
        }
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async UserDataUp (row, state) {
      const iqcformpost = {
        UserData: this.$store.state.Login,
        NewIQCuser: row,
        Qianheclass: {
          Type: 0,
          State: state,
          Text: ''
        }
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuserup', iqcformpost)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
        this.RemoveTab(this.$t('All.新IQC检验单') + '-' + row.Numbers)
        this.isdisabled = true
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async NewIQCuserzaigo (row, index) {
      if (index === 0) {
        for (let indexx = 0; indexx < row.Testlist.length; indexx++) {
          if (row.Testlist[indexx].Determinetype === 1) {
            if (row.Testlist[indexx].Determine === '' || row.Testlist[indexx].Determine === null || row.Testlist[indexx].Determine === undefined) {
              return this.$message.error(`${row.Testlist[indexx].Title}${this.$t('All.项实验室没有做出判断系统无法进行送呈只能暂存')}`)
            }
          }
        }
      }
      const iqcformpost = {
        UserData: this.$store.state.Login,
        NewIQCuser: row,
        Qianheclass: {
          Type: index,
          State: index,
          Text: ''
        }
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuserzaigo', iqcformpost)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
        this.RemoveTab(this.$t('All.新IQC检验单') + '-' + row.Numbers)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async Delete (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const fqcformpost = {
          UserData: this.$store.state.Login,
          NewIQCuser: row,
          Qianheclass: {
            Type: 0,
            State: 1,
            Text: this.$t('All.作废表单')
          }
        }
        loadingInstance.close()
        this.$confirm(this.$t('All.确认要删除'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(async () => {
            const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuserdel', fqcformpost)
            if (res.status !== 200) {
              loadingInstance.close()
              return this.$message.error(res.msg)
            }
            loadingInstance.close()
            this.$message.success(res.msg)
            this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
            this.RemoveTab(this.$t('All.新IQC检验单') + '-' + this.form.Numbers)
          })
          .catch(() => {
            loadingInstance.close()
          })
        loadingInstance.close()
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async NewIQCuserbo () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        this.form.Approval = this.$store.state.Login.Username
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuserbo', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
        this.RemoveTab(this.$t('All.新IQC检验单') + '-' + this.form.Numbers)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async NewIQCuserheke (row) {
      const fqcformpost = {
        UserData: this.$store.state.Login,
        NewIQCuser: this.form,
        Qianheclass: row
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuserheke', fqcformpost)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
        this.RemoveTab(this.$t('All.新IQC检验单') + '-' + this.form.Numbers)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    addDomainmax (item, index) {
      const contr = {
        Index: index + 1,
        Title: item.Title,
        Standard: '',
        Tool: '',
        Frequency: 0,
        Benchmark: '',
        Determine: '',
        Remarks: ''
      }
      this.form.Testlist.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Testlist.length; i++) {
        this.form.Testlist[i].Index = i
      }
    },
    removeDomainmax (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.Testlist.indexOf(item)
          if (index !== -1) {
            this.form.Testlist.splice(index, 1)
          }
          for (let i = 0; i < this.form.Testlist.length; i++) {
            this.form.Testlist[i].Index = i
          }
        })
        .catch(() => {
        })
    },
    fileopen (url) {
      this.ConductIs('')
      this.$store.state.Url = ''
      this.$nextTick(() => { // 异步关闭
        setTimeout(() => {
          // 在延迟2秒后执行的代码
          this.$store.state.Url = this.$Upload + '/' + url
          this.ConductIs('ConductIs')
        }, 500)
      })
    // window.open(url, '_blank')
    },
    addlist (item, index, value) {
      if (value === this.$t('All.增加')) {
        this.addDomain(item, index)
        this.form.NewIQCParcss[index].Determine = ''
        return
      }
      if (value === this.$t('All.删除')) {
        this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(() => {
            this.removeDomain(item, index)
            this.form.NewIQCParcss[index].Determine = ''
          })
          .catch(() => {
            this.form.NewIQCParcss[index].Determine = ''
          })
        return
      }
      if (value === this.$t('All.判定')) {
        const valany = []
        if (item.Text1 !== null && item.Text1 !== '' && item.Text1 !== undefined) {
          valany.push(parseFloat(item.Text1))
        }
        if (item.Text2 !== null && item.Text2 !== '' && item.Text2 !== undefined) {
          valany.push(parseFloat(item.Text2))
        }
        if (item.Text3 !== null && item.Text3 !== '' && item.Text3 !== undefined) {
          valany.push(parseFloat(item.Text3))
        }
        if (item.Text4 !== null && item.Text4 !== '' && item.Text4 !== undefined) {
          valany.push(parseFloat(item.Text4))
        }
        if (item.Text5 !== null && item.Text5 !== '' && item.Text5 !== undefined) {
          valany.push(parseFloat(item.Text5))
        }
        this.form.NewIQCParcss[index].Max = Math.max.apply(Math, valany)
        this.form.NewIQCParcss[index].Min = Math.min.apply(Math, valany)
        var dataine = this.$PublicJs.Calculation(item.Specification, this.form.NewIQCParcss[index].Max, this.form.NewIQCParcss[index].Min)
        this.form.NewIQCParcss[index].Determine = dataine
      }
    },
    Testbox (item, index) {
      this.Test_Item = item
      this.Test_Index = index
      this.Test_Supplier = this.form.Supplier
      this.Test_Product_name = this.form.Product_name
      this.Texttable = true
      this.Test_Numbers = ''
    },
    Texttablebool () {
      this.Texttable = false
    },
    Texttablefunadd (index, row) {
      this.form.Testlist[index].Remarks = row
      this.form.Testlist[index].Determine = ''
      this.Texttable = false
    },
    lookshiyan (row, index) {
      if (row.Remarks === '' || row.Remarks.length < 0) return this.$message.error(this.$t('All.请先填写送测单'))
      if (this.isdisabled) {
        this.Test_Numbers = row.Remarks
        this.Texttable = true
        return
      }
      this.$confirm(this.$t('All.查看实验单修改免做单'), this.$t('All.提示'), {
        cancelButtonText: this.$t('All.查看'),
        confirmButtonText: this.$t('All.重做实验'),
        type: 'warning'
      })
        .then(() => {
          this.Testbox(row, index)
        })
        .catch(() => {
          this.Test_Numbers = row.Remarks
          this.Texttable = true
        })
    },
    //  时间字符串格式化 yyyy-mm-dd
    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      return [year, month, day].join('-')
    },
    chuangjcompany () {
      var inputValue = this.form.Company
      // 移除非字母字符
      inputValue = inputValue.replace(/[^A-Za-z]/g, '')
      // 转换为大写
      inputValue = inputValue.toUpperCase()
      this.form.Company = inputValue
    },
    async Geterpitem () {
      try {
        const { data: res } = await this.$http.get(`/api/Newiqc/Geterpitem?code=${this.form.Item_No}&lot=${this.form.Order_Number}&region=${this.form.Factory}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.form.Product_name = res.response.Pddsc1
        this.form.Company = res.response.Pduom
        this.form.Pddsc2 = res.response.Pddsc2
        this.form.Delivery = res.response.Pduopn
        this.form.Warehousing = res.response.Pduopn
      } catch (error) {
        alert(error)
      }
    },
    printopen () {
      window.open(`/#/vnewiqc/${this.form.Numbers}`)
    },
    async FQCSignloglist (row) {
      try {
        const { data: res } = await this.$http.post('/api/FQC/FQCSignloglist?Numbers=' + row)
        if (res.status !== 200) return
        this.Signlog = res.response
      } catch (error) {
        alert(error)
      }
    },
    async Newiqcprint (type) {
      var fqcprin = {
        Printer: this.prinform.Printer,
        Boxlist: this.prinform.Boxlist,
        Month: this.prinform.Month,
        Date: this.prinform.Date,
        NewIQCuser: this.form,
        UserData: this.$store.state.Login,
        Type: type
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Newiqc/Newiqcprint', fqcprin)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.msg)
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      this.qianhe = false
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.新IQC检验单') + '-' + this.form.Numbers)
    },
    async Gettestbaogao () {
      try {
        if (this.Mz_Numbers.trim().length === 0) {
          this.form.Testlist[this.Mz_Index].Remarks = ''
          this.form.Testlist[this.Mz_Index].Determine = ''
          this.yzdialogVisible = false
          return this.$message.error(this.$t('All.清空订单号成功'))
        }
        const { data: res } = await this.$http.get('/api/Newiqc/Gettestbaogao?Numbers=' + this.Mz_Numbers + '&Day=' + this.Mz_day)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.form.Testlist[this.Mz_Index].Remarks = this.Mz_Numbers
        this.form.Testlist[this.Mz_Index].Determine = res.response.Results
        this.Mz_Numbers = ''
        this.Mz_Index = ''
        this.Mz_day = ''
        this.yzdialogVisible = false
      } catch (error) {
        alert(error)
      }
    }
  }
}
</script>
 <style lang="less" scoped>
   .wbobye{
   left:0;
   right:0;
   top:0;
   bottom:0;
   margin:auto;
   position: fixed;
   height: 100%;
   width: 100%;
   background-color: rgba(255, 255, 255);
   min-width: 903px;
 }
   .el-col {
     border: 1px solid;
     margin-right: -1px;
      margin-bottom: -1px;
     line-height: 4.5vw;
     font-size: 2vw;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space:nowrap;
   }
   .grid-content {
     min-height: 36px;
   }
   .bg-purple-light{

   }
   .row-bg {
     background-color: #f9fafc;
   }
 </style>
